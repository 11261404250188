html, body {
  background-color: #22252a!important;
  color: white!important;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  text-align: center;
}

header {
  margin-top: 30px;
}

.separator {
  width: 50%;
  border: 1px solid black;
}
